
import { ref, computed, defineComponent, onMounted } from 'vue'

import Subscription from './Subscription.vue'
import AcEmpty from '@web/ui-kit/components/AcEmpty.vue'
import AsPreloader from '@web/components/AsPreloader.vue'

import { useStore } from '@web/store'
import { useRoute } from 'vue-router'
import sentry from '@web/common/sentry'
import Swal from 'sweetalert2'
import i18n from '@web/plugins/i18n'
import Logger from '@web/common/Logger'
import { Subscription as ISubscription } from '@web/types/Subscription'

export default defineComponent({
  name: 'CSubscriptions',
  components: {
    AsPreloader,
    Subscription,
    AcEmpty
  },
  setup () {
    const store = useStore()
    const route = useRoute()

    const showWarning = ref(route.params.warning)

    onMounted(async () => {
      try {
        if (!store.getters['subscription/isLoaded']) {
          await store.dispatch('subscription/fetchCachedSubscriptions')
        }
      } catch (e) {
        Swal.fire({
          title: i18n.global.t('error.error'),
          text: i18n.global.t('error.subscriptions'),
          icon: 'error'
        })
        sentry.captureException(e)
        Logger.error(e)
      }
    })

    const isLoaded = computed(() => store.getters['subscription/isLoaded'])

    const subscriptions = computed<ISubscription[]>(() => {
      const _subscriptions = store.getters['subscription/subscriptions']
      return _subscriptions.sort((a, b) => (b.nextPayAt || 0) - (a.nextPayAt || 0))
    })

    const subscriptionGrouped = computed<Record<string, {
      title: string;
      subscriptions: ISubscription[]
    }>>(() => {
      return subscriptions.value.reduce((acc, subscription) => {
        const status = subscription.isActive || subscription.isLifetime ? 'active' : 'other'
        if (Object.prototype.hasOwnProperty.call(acc, status)) {
          acc[status].subscriptions.push(subscription)
        } else {
          acc[status] = {
            title: status === 'active' ? i18n.global.t('Active') : i18n.global.t('NotActive'),
            subscriptions: [subscription]
          }
        }
        return acc
      }, {})
    })

    return {
      showWarning,
      isLoaded,
      subscriptions,
      subscriptionGrouped
    }
  }
})
