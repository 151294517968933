import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a2a071c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subscriptions" }
const _hoisted_2 = {
  key: 0,
  class: "subscriptions_warning warning"
}
const _hoisted_3 = { class: "warning_title" }
const _hoisted_4 = { class: "warning_text" }
const _hoisted_5 = { class: "warning_line" }
const _hoisted_6 = { class: "subscriptions_sectionWrapper" }
const _hoisted_7 = {
  key: 0,
  class: "subscriptions_section"
}
const _hoisted_8 = { class: "subscriptions_caption" }
const _hoisted_9 = { class: "subscriptions_wrapper" }
const _hoisted_10 = {
  key: 1,
  class: "subscriptions_section"
}
const _hoisted_11 = { class: "subscriptions_caption" }
const _hoisted_12 = { class: "subscriptions_wrapper" }
const _hoisted_13 = {
  key: 1,
  class: "subscriptions_preloader preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_subscription = _resolveComponent("subscription")!
  const _component_ac_empty = _resolveComponent("ac-empty")!
  const _component_as_preloader = _resolveComponent("as-preloader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoaded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.showWarning)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('restoreAccessPlatform.title')), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.$t('restoreAccessPlatform.text1')) + " ", 1),
                    _createVNode(_component_router_link, {
                      class: "warning_link",
                      to: {name: 'support'}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('restoreAccessPlatform.support')), 1)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(_toDisplayString(_ctx.$t('restoreAccessPlatform.text2')), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.subscriptionGrouped.active && _ctx.subscriptionGrouped.active.subscriptions.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.subscriptionGrouped.active.title), 1),
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptionGrouped.active.subscriptions, (subscription) => {
                      return (_openBlock(), _createBlock(_component_subscription, {
                        key: `active-subscription-${subscription.id}`,
                        subscription: subscription,
                        class: "subscriptions_subscription"
                      }, null, 8, ["subscription"]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.subscriptionGrouped.other && _ctx.subscriptionGrouped.other.subscriptions.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.subscriptionGrouped.other.title), 1),
                  _createElementVNode("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptionGrouped.other.subscriptions, (subscription) => {
                      return (_openBlock(), _createBlock(_component_subscription, {
                        key: `other-subscriptions-${subscription.id}`,
                        subscription: subscription,
                        class: "subscriptions_subscription"
                      }, null, 8, ["subscription"]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.subscriptions.length === 0)
              ? (_openBlock(), _createBlock(_component_ac_empty, {
                  key: 2,
                  class: "subscriptions_section"
                }, {
                  text: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('emptySubscriptions')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_as_preloader, { class: "preloader_preloader" })
        ]))
  ]))
}